@import "/src/assets/scss/variables";
@import "/src/assets/scss/mixins";
@import "/src/assets/scss/typography";

.ag-overlay {
  .loader-wrapper {
    background-color: rgb(43, 43, 47,0.9);
    top: 0;
    left: 0;
   //opacity: 0.7;
    // &__container {
    //     span {
    //       color: $black;
    //     }
    // }
  }
  .ag-overlay-no-rows-wrapper{
    padding-top:4rem;
  }
}

.ag-theme-alpine {
  width: 100%;
  height: calc(100vh - 13rem);
  position: relative;

  .ag-body-viewport {
    max-height: calc(100vh - 17rem);
  }
  .ag-layout-auto-height .ag-center-cols-container{
    min-height: auto;
  }

  font: {
    family: $ey-font-light !important;
  }

  .ag-icon-asc {
    background: transparent url('../../../../assets/media/images/sort_up.svg') center/contain no-repeat;
    color: transparent;
    @include square(0.7rem);
  }

  .ag-icon-desc {
    background: transparent url('../../../../assets/media/images/sort_down.svg') center/contain no-repeat;
    color: transparent;
    @include square(0.7rem);
  }

  .ag-icon-none {
    background: url('../../../../assets/media/images/sort_up.svg') left top no-repeat, url('../../../../assets/media/images/sort_down.svg') left bottom no-repeat;
    color: transparent;
    @include square(0.8rem);
  }

  .ag-body-viewport {
    overflow-y: auto;
    @include scrollbars(0.4rem, lighten($grey-450, 25%), $grey-450);
  }

  .ag-theme-alpine .ag-header {
    border: none !important;
    background-color: #2b2b2f !important;
    margin-bottom: 0.1rem !important;
  }

  .ag-root-wrapper {
    border: none !important;
    background-color: #2b2b2f !important;

    .ag-paging-row-summary-panel {
      display: none;
    }
  }

  .ag-header {
    border: none !important;
    background-color: #2b2b2f !important;
    margin-bottom: 0.1rem !important;
    color: #d8d8dc;
  }

  .ag-header-row {
    color: #d8d8dc !important;
    border-bottom: 0.06rem solid $grey-450 !important;

    .ag-header-cell-text {
      text-overflow: clip;
      overflow: visible;
      white-space: normal;
      height: auto;
      font: {
        size: 0.7rem !important;
      }
    }
  }

  .ag-row {
    background-color: $grey-650 !important;
    color: $white;
    border-bottom: 0.06rem solid $grey-450 !important;

    .ag-cell {
      color: $white;

      font: {
        size: 0.75rem !important;
      }

      &:not(:first-child) {
        padding-top: 0.5rem;
      }

      .avatar-cell-renderer {
        img {
          width: 2rem !important;
          height: 2rem !important;
        }
      }
    }
  }

  .ag-row-odd {
    background-color: #2b2b2f !important;
    color: $white;
  }

  .ag-sort-mixed-icon {
    display: block !important;
  }

  .ag-paging-panel {
    background: transparent !important;
    border: none !important;
    color: white !important;
  }
  .ag-sort-order{
    display: none!important;
  }
}

.active-users,
.user-request {
  .action-cell-renderer {
    margin: 0;
    display: flex;
    justify-content: flex-end;

    img {
      @include square(0.8rem);
    }
  }

  .ag-overlay-loading-center {
    color: $white;
  }
}

.ag-theme-alpine .ag-ltr .ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected),
.ag-theme-alpine .ag-ltr .ag-context-menu-open .ag-cell-focus:not(.ag-cell-range-selected),
.ag-theme-alpine .ag-ltr .ag-has-focus .ag-full-width-row.ag-row-focus .ag-cell-wrapper.ag-row-group,
.ag-theme-alpine .ag-ltr .ag-cell-range-single-cell,
.ag-theme-alpine .ag-ltr .ag-cell-range-single-cell.ag-cell-range-handle,
.ag-theme-alpine .ag-rtl .ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected),
.ag-theme-alpine .ag-rtl .ag-context-menu-open .ag-cell-focus:not(.ag-cell-range-selected),
.ag-theme-alpine .ag-rtl .ag-has-focus .ag-full-width-row.ag-row-focus .ag-cell-wrapper.ag-row-group,
.ag-theme-alpine .ag-rtl .ag-cell-range-single-cell,
.ag-theme-alpine .ag-rtl .ag-cell-range-single-cell.ag-cell-range-handle {
  border-color: transparent !important;
}

.ag-paging-panel {
  font-size: .7rem;

  .ag-paging-row-summary-panel {
    margin-right: 0;
  }
}

.ag-header-row.ag-header-row-column:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background: #232323;
}

.gpo-grid {
  &__page-size {
    width: 5rem;
    //position: absolute;
    //bottom: calculateRem(3px);
    right: 16rem;
    position: fixed;
  }
}