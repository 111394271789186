@import "/src/assets/scss/variables";
@import "/src/assets/scss/mixins";

.tooltip-trigger-container {
    position: relative;
    @include flex();
}

.tooltip-text {
    position: absolute;
    z-index: 9999;
    width: auto;
    max-width: 26%;
    background-color: #FFF;
    color: #2E2E38;
    padding: 10px;
    font-size: 12px;
    top: 100%;
    left: 50%;
    transform: translate(calc(-50% - 0.55rem), calc(-100% - 0.7rem));

    &::after {
        content: "";
        display: inline-block;
        height: 0;
        border-left: solid 0.7rem transparent;
        border-right: solid 0.7rem transparent;
        border-top: solid 0.7rem #FFF;
        top: 100%;
        left: 50%;
        transform: translate(-50%, -10%);
        position: absolute;
    }
}