@import "/src/assets/scss/variables";
@import "/src/assets/scss/mixins";

.ag-theme-alpine .ag-root-wrapper {
    background-color: transparent !important;

    .ag-paging-panel {
        background-color: transparent !important;
    }

    .ag-header {
        background-color: $table-bg !important;
    }

    .ag-row {
        background-color: $table-bg !important;

        &:hover {
            background-color: #3B3B3B !important;
        }

        &.selectedRow {
            background-color: #3B3B3B !important;

            .ag-cell:first-child {
                border-left: 0.2rem solid #D9D9D9;
            }
        }

    }
}

.question-details {
    background-color: #222;
    height: 100%;

    &__container {
        height: 100%;
    }

    &__header {
        padding: 1.77rem;
        border-bottom: 0.06rem solid $sidebar-border;
        border-right: 0.06rem solid $sidebar-border;
        background-color: $sidebar-bg;
        @include flex(row, space-between, center);

        &__left {
            align-self: flex-end;

            h2 {
                color: $nav-bg;
            }

            p {
                margin-top: 0.25rem;
                color: $grey-300;
                font-size: 12px;
                font-weight: 300;
            }
        }

        &__right {
            @include flex(row, flex-end, center);
            gap: 0.2rem;

            button {
                background-color: $white;
                border: 0.06rem solid $white;
                font-weight: bold;
                padding: 0.5rem 1.25rem;
                color: $black;
                cursor: pointer;
                @include flex(row, center, center);
                gap: 0.5rem;
            }
        }
    }

    &__body {
        padding: 1rem;
        margin: 1.5rem;
        background-color: $black-250;
        border: 1px solid $grey-450;
        border-radius: 0.5rem;
        height: calc(100vh - 7rem);
    }

    &__table {
        .ag-theme-alpine {
            height: calc(100vh - 23.5rem);

            .ag-root-wrapper-body {
                max-height: calc(100vh - 27.5rem) !important;
            }

            .ag-body-viewport {
                overflow-y: auto;
                @include scrollbars(0.4rem, lighten($grey-450, 25%), $grey-450);
            }

            .ag-layout-auto-height .ag-center-cols-container,
            .ag-layout-auto-height .ag-center-cols-clipper {
                min-height: 2rem;
            }
        }

        .table-selectedRow-details-wrapper {
            position: fixed;
            top: 0;
            left: 0;
            z-index: 999;
            @include square(100%);
            background-color: rgba(138, 138, 138, 0.2);

            .table-selectedRow-details {
                @include flex(column, flex-start, flex-start);
                background-color: $bg-modal;
                width: 25rem;
                height: 60vh;
                position: absolute;
                z-index: 2;
                right: 2.5rem;
                color: $white;
                position: absolute;
                top: 34vh;
                box-shadow: -2px 2px rgb(0, 0, 0, 0.25);

                &__header {
                    @include flex(row, space-between, center);
                    width: 100%;
                    padding: 0.5rem;
                    font-size: 0.9rem;
                    border-bottom: 1px solid $grey-450;

                    .header-close {
                        cursor: pointer;
                    }

                    flex:1;
                }

                &__body {
                    width: 100%;
                    @include flex(column, flex-start, flex-start);
                    flex: 10;

                    .body-tabs {
                        flex: 1;
                        position: relative;
                        border-bottom: 1px solid $grey-450;
                        width: 100%;
                        line-height: 1.5rem;
                        @include flex(row, space-around, center);
                        gap: 2rem;

                        span {
                            position: relative;
                            cursor: pointer;
                            color: $light-white;
                            transition: color 0.3s;
                            padding: 0.5rem 0.75rem;

                            font: {
                                size: 0.8rem;
                            }

                            &:after {
                                position: absolute;
                                left: 0;
                                right: 0;
                                bottom: 0;
                                display: block;
                                width: 0;
                                @include size(0, 0.15rem);
                                background-color: $white;
                                margin: auto;
                                content: "";
                                transition: width 0.3s;
                            }

                            &.active {
                                color: $white;
                                font-weight: 600;

                                &:after {
                                    width: 100%;
                                }
                            }
                        }
                    }

                    .body-tabs-data {
                        flex: 10;
                        padding: 1rem 0.5rem;
                        width: 100%;

                        &__chatWrapper {
                            color: $white;
                            font-size: 0.85rem;
                            @include flex(column, flex-start, flex-start);
                            gap: 1rem;
                            height: 100%;

                            section {
                                @include flex(row, flex-start, flex-start);
                                max-width: 15rem;
                                gap: 0.5rem;
                                @include scrollbars(0.25rem, lighten($grey-450, 25%), $grey-450);

                                img {
                                    @include square(2rem);

                                    &.profile-pic {
                                        margin-right: 0 !important;
                                    }
                                }

                                .chat-data {
                                    @include flex(column, flex-start, flex-start);
                                    gap: 0.5rem;
                                    padding: 0.5rem;
                                    width: 100%;
                                    word-break: break-word;

                                    a {
                                        color: $yellow-400 !important;
                                    }
                                }

                                &.user,
                                &.agent {
                                    .chat-data {
                                        background-color: #282828;
                                    }
                                }

                                &.bot {
                                    align-self: flex-end;

                                    .chat-data {
                                        background-color: #3B3B3B;
                                    }
                                }

                                &.new-agent,
                                .agent-left {
                                    align-items: center;
                                }

                                .chat-data {
                                    &__header {
                                        width: 100%;
                                        @include flex(row, space-between, center);
                                        gap: 0.5rem;

                                        .header-date {
                                            font-size: 0.7rem;
                                        }
                                    }

                                    &__body {
                                        max-height: 8rem;
                                        overflow: auto;
                                        @include scrollbars(0.25rem, lighten($grey-450, 25%), $grey-450);
                                    }
                                }
                            }
                        }

                        &__documentListWrapper {
                            background-color: #282828;
                            @include flex(column, flex-start, flex-start);
                            width: 100%;
                            padding: 1rem;
                            max-height: 35vh;
                            overflow: auto;
                            @include scrollbars(0.25rem, lighten($grey-450, 25%), $grey-450);
                            gap: 1rem;

                            .documentList-header {
                                @include flex(row, space-between, center);
                                width: 100%;
                                font-size: 0.7rem;
                                gap: 1rem;

                                span {
                                    width: 22.5%;
                                }

                                span:first-child {
                                    width: 45%;
                                }
                            }

                            .documentList-body {
                                @include flex(row, space-between, center);
                                width: 100%;
                                font-size: 0.8rem;
                                gap: 1rem;

                                &__doc {
                                    @include flex(row, flex-start, center);
                                    gap: 0.5rem;
                                    width: 45%;

                                    span.doc-name {
                                        white-space: nowrap;
                                        display: inline-block;
                                        width: 60%;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                    }

                                    span.version {
                                        background-color: $yellow-400;
                                        color: $black;
                                        padding: 0.1rem 0.3rem;
                                        border-radius: 0.5rem;
                                        font-weight: bold;

                                    }

                                    img.view-doc {
                                        cursor: pointer;
                                        @include square(0.9rem);
                                    }
                                }

                                &__status {
                                    @include flex(row, flex-start, center);
                                    gap: 0.5rem;
                                    width: 22.5%;
                                }

                                &__page {
                                    white-space: nowrap;
                                    display: inline-block;
                                    width: 22.5%;
                                    overflow: hidden;
                                    text-overflow: ellipsis;

                                }
                            }
                        }
                    }
                }

            }
        }
    }

    &__selected-filter {
        @include flex(row, space-between, center);
        color: $white;
        gap: 0.5rem;
        text-wrap: nowrap;

        &__wrapper {
            @include flex(row, space-between, center);
            gap: 0.5rem;
            overflow-x: auto;
            padding-bottom: 0.7rem;
            @include scrollbars(0.4rem, lighten($grey-450, 25%), $grey-450);
        }

        &__label {
            font-size: calculateRem(12px);
        }

        &__clear {
            font-size: calculateRem(11px);
            text-wrap: nowrap;

            &:hover {
                cursor: pointer;
                text-decoration: underline;
            }
        }

        &__refresh-btn {
            @include flex(row, flex-start, center);
            color: $white;
            background-color: transparent;
            border: none;
            gap: 0.3rem;
            cursor: pointer;

            p {
                font-size: calculateRem(12px);
            }
        }
    }

    &__chip {
        display: flex;
        align-items: center;
        padding: calculateRem(4px) calculateRem(6px);
        border: calculateRem(2px) solid $white;
        border-radius: 1rem;
        gap: 0.3rem;

        p {
            font-size: calculateRem(11px);
        }
    }

    &__total-count {
        @include flex(row, space-between, center);
        margin-bottom: 0.5rem;
        gap: 2rem;

        div {
            @include flex(row, center, center);
            flex: 1;
            //width: 32%;
            color: $white;
            background-color: $table-bg;
            padding: 0.8rem 2rem;
        }

        &__label {
            font-size: 0.8rem;
            color: $grey-300;
            margin-left: 0.5rem;
            margin-right: 1.5rem;
        }
    }

    &__action-panel {
        @include flex(row, space-between, center);
        background-color: $table-bg;
        padding: 0.75rem;
        gap: 0.5rem;
        margin-bottom: 0.75rem;

        &__export-btn {
            @include flex(row, flex-start, center);
            color: $white;
            background-color: #2b2b2f;
            border: none;
            gap: 0.3rem;
            cursor: pointer;

            p {
                font-size: calculateRem(12px);
            }
        }

        &__right {
            @include flex(row, flex-start, center);
            gap: 1rem;
        }

        &__filter-btn {
            position: relative;
            align-items: center;
            display: flex;
            cursor: pointer;

            span {
                color: $white;
                font-size: calculateRem(12px);
                margin-left: 0.3rem;
                margin-right: 1rem;
            }

            &::after {
                content: '';
                height: 100%;
                width: 1px;
                background-color: #3a3a4a;
                right: 0;
                top: 0;
                position: absolute;
            }
        }

        &__search {
            background-color: $table-bg;
            @include flex(row, space-between, center);
            gap: 0.5rem;

            img {
                @include square(1rem);
                cursor: pointer;
            }

            input {
                width: 20rem;
                color: $white;
                background-color: transparent;
                border: none;
                outline: none;

                &::placeholder {
                    font-size: 0.75rem;
                    color: $white;
                }
            }
        }
    }
}