@import "/src/assets/scss/variables";
@import "/src/assets/scss/mixins";

.pie-chart-container {
    @include flex(column, center, center);
    padding: 1.5rem;
    position: relative;
    width: 35%;

    .chart-title {
        @include flex();
        gap: 0.3rem;
        font-size: 14px;
        white-space: nowrap;
        position: absolute;
        top: 7%;
        left: 50%;
        transform: translate(-40%, -7%);
        z-index: 1;

        img {
            height: 14px;
        }
    }

    .legend-container {
        @include flex(column, center, flex-start);
        gap: 0.4rem;

        .legend-line {
            @include flex(row, flex-start, center);
            gap: 0.4rem;
            font-size: 10px;

            .legend-dot {
                height: 10px;
                width: 10px;
                border-radius: 50%;
            }
        }
    }
}