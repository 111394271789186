@import 'assets/scss/mixins';
@import 'assets/scss/variables';

.gpo-button {
    align-items: center;
    border-radius: 0;
    border-style: solid;
    border-width: 1px;
    cursor: pointer;
    display: flex;
    font-family: $primary-font;
    font-size: $btn-font-size;
    font-weight: $btn-font-weight;
    justify-content: center;
    line-height: 1.572;
    min-width: calculateRem(88px);
    overflow: visible;
    padding: $btn-padding;
    position: relative;
    text-align: center;
    text-decoration: none;
    transition: color $ease-in-out-fast, background $ease-in-out-fast,
        box-shadow $ease-in-out-fast, border-color $ease-in-out-fast;


    path {
        fill: currentColor;
        transition: fill $ease-in-out-fast;
    }

    .gpo-icon {
        margin: 0;
        flex-shrink: 0;
        color: inherit;

        svg {
            display: block;
            height: calculateRem(24px);
            width: calculateRem(24px);
        }
    }

    &::-moz-focus-inner {
        border: 0;
    }

    &:disabled {
        cursor: not-allowed;
    }
}

.gpo-button-primary,
.gpo-button-primary-alt {

    &.gpo-active,
    &:hover:not(:disabled),
    &:focus {
        background-color: $btn-primary-hover-bg-color;
        border-color: $btn-primary-hover-border-color;
        color: $btn-primary-hover-color;
    }
}

.gpo-button-primary {
    background-color: $btn-primary-bg-color;
    border-color: $btn-primary-border-color;
    color: $btn-primary-color;

    &:disabled {
        background-color: $btn-primary-disabled-bg-color;
        border-color: $btn-primary-disabled-border-color;
        color: $btn-primary-disabled-color;
    }
}

.gpo-button-primary-alt {
    background-color: $btn-primary-alt-bg-color;
    border-color: $btn-primary-alt-border-color;
    color: $btn-primary-alt-color;

    &.gpo-active,
    &:hover:not(:disabled),
    &:focus {
        background-color: $btn-primary-alt-hover-bg-color;
        border-color: $btn-primary-alt-hover-border-color;
        color: $btn-primary-alt-hover-color;
    }

    &:disabled {
        background-color: $btn-primary-alt-disabled-bg-color;
        border-color: $btn-primary-alt-disabled-border-color;
        color: $btn-primary-alt-disabled-color;
    }
}

.gpo-button-secondary {
    border-color: $btn-secondary-border-color;
    background-color: $btn-secondary-bg-color;
    color: $btn-secondary-color;

    &:focus {
        outline: none;
        background-color: $btn-secondary-focus-bg-color;
        border-color: $btn-secondary-focus-border-color;
        color: $btn-secondary-focus-color;
    }

    &.gpo-active,
    &:active {
        background-color: $black;
        color: $white;
    }

    &:disabled {
        background-color: $btn-secondary-disabled-bg-color;
        border-color: $btn-secondary-disabled-border-color;
        color: $btn-secondary-disabled-color;
    }

    &:hover:not(:disabled) {
        background-color: $btn-secondary-hover-bg-color;
        border-color: $btn-secondary-hover-border-color;
        color: $btn-secondary-hover-color;
    }
}

.gpo-button-warn {
    background-color: $btn-warn-bg-color;
    border-color: $btn-warn-border-color;
    color: $btn-warn-color;

    &:hover {
        background-color: $btn-warn-hover-bg-color;
        border-color: $btn-warn-hover-border-color;
        color: $btn-warn-hover-color;
    }

    &:focus {
        background-color: $btn-warn-focus-bg-color;
        border-color: $btn-warn-focus-border-color;
        color: $btn-warn-focus-color;
    }

    &:disabled {
        background-color: $btn-warn-disabled-bg-color;
        border-color: $btn-warn-disabled-border-color;
        color: $btn-warn-disabled-color;
    }
}

.gpo-button-medium {
    padding: $btn-padding-medium;
    min-width: calculateRem(94px);
}

.gpo-button-large {
    padding: $btn-padding-large;
    min-width: calculateRem(100px);
}