@import 'assets/scss/mixins';
@import 'assets/scss/variables';

.pagination-select {
    &__form-field {
        position: relative;
        width: 5.5rem;
    }

    &__container {
        position: relative;

        &:hover {
            background-color: $dropbg;
        }
    }

    &__arrow {
        height: 1.5rem;
        width: 1.5rem;

        &--open {
            transform: rotate(180deg);
        }
    }

    &__trigger {
        @include flex(row, space-between, center);
        background: none;
        min-height: 2.5rem;
        border: none;
        position: relative;
        text-align: left;
        width: 100%;
        cursor: pointer;
    }

    &__input-text {
        width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        color: $white;
        font-size: calculateRem(11px);
    }

    &__options-wrapper {
        width: 100%;
        position: absolute;
        bottom: calculateRem(40px);
    }

    &__options {
        width: 100%;
        display: block;
        max-height: 9rem;
        overflow-y: auto;
        background-color: $dropbg;
        @include scrollbars(0.4rem, lighten($grey-450, 25%), $grey-450);
    }

    &__option {
        padding: 0.8rem 0.2rem;
        max-height: 2.5rem;
        display: flex;
        align-items: center;
        cursor: pointer;
        color: $white;
        font-size: calculateRem(12px);

        &:hover {
            background-color: $grey-600;
            color: $yellow-400;
        }

        input {
            height: calculateRem(16px);
            width: calculateRem(16px);
        }

        label {
            margin-left: 0.5rem;
        }
    }
}