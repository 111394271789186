@import "/src/assets/scss/variables";
@import "/src/assets/scss/mixins";
$animationLength: 1.5s;
$animationRadius: 12px;
$dotSize: 8px;

.loader-wrapper {
  background-color: rgb(43, 43, 47, 0.7);
  @include square(100%);
  position: absolute;
  z-index: 999;

  &__fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
  }

  &__container {
    @include centre;

    .tbl-loader {
      position: relative;
      width: $animationRadius * 3 + $dotSize;
      height: $dotSize;
      margin: $animationRadius auto;

      h4 {
        color: $white;
        left: -1rem;
        position: relative;
        top: 3rem;
        font-weight: 300;
      }

      .dot {
        display: inline-block;
        width: $dotSize;
        height: $dotSize;
        border-radius: $dotSize * 0.5;
        background: $grey-200;
        position: absolute;

        &.dot_1 {
          animation: animateDot1 $animationLength linear infinite;
          left: $animationRadius;
          background: $yellow-400;
        }

        &.dot_2 {
          animation: animateDot2 $animationLength linear infinite;
          animation-delay: $animationLength / 3;
          left: $animationRadius * 2;
        }

        &.dot_3 {
          animation: animateDot3 $animationLength linear infinite;
          left: $animationRadius;
        }

        &.dot_4 {
          animation: animateDot4 $animationLength linear infinite;
          animation-delay: $animationLength / 3;
          left: $animationRadius * 2;
        }
      }
    }

    @keyframes animateDot1 {
      0% {
        transform: rotate(0deg) translateX(-$animationRadius);
      }

      25% {
        transform: rotate(180deg) translateX(-$animationRadius);
      }

      75% {
        transform: rotate(180deg) translateX(-$animationRadius);
      }

      100% {
        transform: rotate(360deg) translateX(-$animationRadius);
      }
    }

    @keyframes animateDot2 {
      0% {
        transform: rotate(-0deg) translateX(-$animationRadius);
      }

      25% {
        transform: rotate(-180deg) translateX(-$animationRadius);
      }

      75% {
        transform: rotate(-180deg) translateX(-$animationRadius);
      }

      100% {
        transform: rotate(-360deg) translateX(-$animationRadius);
      }
    }

    @keyframes animateDot3 {
      0% {
        transform: rotate(0deg) translateX($animationRadius);
      }

      25% {
        transform: rotate(180deg) translateX($animationRadius);
      }

      75% {
        transform: rotate(180deg) translateX($animationRadius);
      }

      100% {
        transform: rotate(360deg) translateX($animationRadius);
      }
    }

    @keyframes animateDot4 {
      0% {
        transform: rotate(-0deg) translateX($animationRadius);
      }

      25% {
        transform: rotate(-180deg) translateX($animationRadius);
      }

      75% {
        transform: rotate(-180deg) translateX($animationRadius);
      }

      100% {
        transform: rotate(-360deg) translateX($animationRadius);
      }
    }
  }
}