@import "/src/assets/scss/variables";
@import "/src/assets/scss/mixins";

.side-bar {
  height: 100%;
  &__container {
    background-color: $sidebar-bg;
    border-right: 0.06rem solid $sidebar-border;
    height: 100%;
    header {
      padding: 1.67rem;
      border-bottom: 0.06rem solid $sidebar-border;
      img {
        @include square(1.75rem);
      }
      span {
        color: $white;
        margin-left: 0.5rem;
      }
    }
    nav {
      padding: 1rem 1.2rem;
      cursor: pointer;
      .menu {
        color: $light-white;
        @include flex(row, flex-start, center);
        padding: 0.75rem;
        cursor: pointer;
        transition: all 0.3s ease;
        border-radius: 0.5rem;
        font-size: .9rem;
        &:not(:last-child) {
          margin-bottom: 1rem;
        }
        img {
          margin-right: 1.2rem;
          @include square(1.1rem);
        }
        &.active{
          background-color: $nav-bg;
          color: $app-bg;
        }
      }
    }
    .user-details {
      @include flex(row, flex-start, center);
      position: absolute;
      bottom: 1rem;
      left: 1.2rem;
      img{
          @include square(2.3rem);
          margin-right: .75rem;
          border-radius: 50%;
      }
      &__action {
        @include flex(column, center, flex-start);
        span {
          color: $light-white;
          cursor: pointer;
          font: {
            size: .7rem;
        }
        }
        strong {
          color: $white;
          margin-bottom: .3rem;
          @include ellipsis;
          max-width: 12vw;
          font: {
              size: .75rem;
          }
        }
      }
    }
  }
}
