@import 'assets/scss/mixins';
@import 'assets/scss/variables';

.file-uploader-item {
    background-color: #222222;

    &__wrapper {
        padding: 1rem;
        @include flex(row, space-between, center);
        font-size: calculateRem(12px);

    }

    &__left {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        width: 75%;

        span {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    &__right {
        display: flex;
        align-items: center;
        gap: 1rem;

        img {
            @include size(0.8rem, 0.8rem);
            cursor: pointer;
        }
    }
}