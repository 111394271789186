@import 'assets/scss/mixins';
@import 'assets/scss/variables';

//Footer
.modal-footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: calculateRem(20px);
    border-top: 1px solid $modal-footer-border-color;
    gap: 0.8rem;

    button {
        margin-top: 0;
        margin-bottom: 0;
    }
}