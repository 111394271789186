@import "/src/assets/scss/variables";
@import "/src/assets/scss/mixins";
@import "/src/assets/scss/typography";

.map-container {
    margin: 1rem;

    &__title {
        font-size: 16px;
        font-weight: 400;
        color: #FFF;

        img {
            transform: rotate(180deg);
            margin-right: 0.3rem;
            cursor: pointer;
        }
    }

    &__custom-loader,
    &__no-data {
        position: relative;
        width: 50%;
        left: 50%;
        margin-top: 10rem;
        transform: translate(-50%);
        text-align: center;
    }

    &__body {
        border: 1px solid grey;
        margin-top: 0.5rem;
        height: 70%;
        position: relative;

        .legend-wrapper {
            position: absolute;
            left: 5%;
            bottom: 10%;
            @include flex();
            gap: 0.5rem;
            height: 30%;
            min-height: 7rem;

            &__legend {
                width: 0.5rem;
                height: 100%;
                background-image: linear-gradient(180deg, #351C21, #42152D, #5A0A42, #922B73, #B14891, #C981B2, #C4C4CD);
            }

            &__numbers {
                height: 100%;
                @include flex(column-reverse, space-between, baseline);
            }
        }
    }

    .hoverinfo {
        border: none !important;
        color: #000;
        font-family: $ey-font-light;
        transform: translate(-50%, -250%);

        &__content {
            padding: 0.2rem 0.2rem !important;
            font-size: 12px !important;
            @include flex();

            .country-flag {
                margin-right: 0.2rem;
                width: 1em;
            }

            .user-count {
                font-weight: 600;
                margin-left: 0.5rem;
            }
        }

        &::after {

            content: "";
            display: inline-block;
            border-right: solid 0.5rem transparent;
            border-bottom: solid 0.5rem transparent;
            border-left: solid 0.5rem transparent;
            border-top: solid 0.5rem #FFF;
            bottom: -50%;
            left: 50%;
            transform: translate(-50%, -10%);
            position: absolute;
        }
    }
}