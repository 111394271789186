@import 'assets/scss/mixins';
@import 'assets/scss/variables';

.file-uploader {
    display: inline-block;
    width: 100%;
    text-align: center;
    position: relative;
    margin-bottom: 1rem;

    &__error {
        color: $file-uploader-invalid-font-color;
        text-align: start;
        margin-top: 0.5rem;
        font-size: calculateRem(11px);
    }

    .file-uploader-label-text {
        font-family: $primary-font;
        color: $white;
    }

    &.invalid {
        .dropzone-container {
            background-image: none;
            border: solid 1px $error;
            background-color: $file-uploader-invalid-background-color;
        }

        .icon svg {
            fill: $file-uploader-invalid-icon-color;
        }

    }

    &.disabled {
        cursor: not-allowed;

        >div {
            pointer-events: none;
        }

        .dropzone-container {
            background-image: linear-gradient(to right, $file-uploader-disabled-border-color) 50%, transparent 50%,
                linear-gradient(to right, $file-uploader-disabled-border-color) 50%, transparent 50%,
                linear-gradient(to bottom, $file-uploader-disabled-border-color) 50%, transparent 50%,
                linear-gradient(to bottom, $file-uploader-disabled-border-color) 50%, transparent 50%;
            background-color: $file-uploader-disabled-background-color;
        }

        .icon {
            fill: $file-uploader-disabled-icon-color;
        }

        .file-uploader-label-text {
            color: $file-uploader-disabled-font-color;
        }
    }

    .icon {
        width: calculateRem(35px);
        height: auto;
        fill: $file-uploader-icon-color;

        svg {
            width: 100%;
            height: 100%;
        }
    }
}

.dropzone-container {
    width: 100%;
    height: 100%;
    border: none;
    font-size: $file-uploader-font-size;
    color: $file-uploader-font-color;
    font-weight: $weight-regular;
    background-color: #222222;
    background-image: linear-gradient(to right, #4E4E5D 50%, transparent 50%), linear-gradient(to right, #4E4E5D 50%, transparent 50%), linear-gradient(to bottom, #4E4E5D 50%, transparent 50%), linear-gradient(to bottom, #4E4E5D 50%, transparent 50%);
    background-position: left top, left bottom, left top, right top;
    background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
    background-size: 20px 1px, 20px 1px, 1px 20px, 1px 20px;
    padding: calculateRem(20px);

    &:hover,
    &:focus {
        background-image: linear-gradient(to right, $file-uploader-hover-border-color) 50%, transparent 50%,
            linear-gradient(to right, $file-uploader-hover-border-color) 50%, transparent 50%,
            linear-gradient(to bottom, $file-uploader-hover-border-color) 50%, transparent 50%,
            linear-gradient(to bottom, $file-uploader-hover-border-color) 50%, transparent 50%;
    }
}

.file-uploader-label {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}