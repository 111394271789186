@import "/src/assets/scss/variables";
@import "/src/assets/scss/mixins";

.user-engagement {
    margin-bottom: 1rem;

    &-summary {
        @include flex(row, flex-start, stretch);
        border: 1px solid $grey-450;
        margin-top: 0.5rem;
        background-color: $app-bg;
        border-radius: 4px;

        &__left {
            @include flex(row, space-between, center);
            width: 45%;
            flex-wrap: wrap;
            padding: 1.5rem 2rem;

            &--block {
                @include flex(row, flex-start, center);
                width: 50%;
                margin-bottom: 2rem;
                gap: 0.75rem;

                .block-label {
                    @include flex(row, flex-start, center);
                    gap: 0.2rem;
                    font-size: 12px;
                    color: $grey-300;
                    margin-bottom: 0.1rem;

                    span {
                        @include flex();
                    }
                }

                .block-value {
                    @include flex(row, flex-start, center);
                    gap: 0.2rem;
                    font-size: 14px;

                    span {
                        @include flex();
                        cursor: pointer;
                    }
                }

                &:nth-last-child(1),
                &:nth-last-child(2) {
                    margin-bottom: 0;
                }
            }
        }

        &__right {
            padding: 1.5rem 2rem 1.5rem 0;
            width: 55%;

            .label {
                font-size: 12px;
                color: $grey-300;
                margin-left: 0.5rem;
                margin-bottom: 0.2rem;
                @include flex(row, flex-start, center);
                gap: 0.2rem;
            }

            .documents-container {
                position: relative;
                height: 100%;

                &--loading {
                    height: 230px;
                }

                .no-data {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -100%);
                }
            }

            .rank {
                position: absolute;
                outline: 0.4px solid #FFF;

                .doc-details {
                    @include flex(column, center, center);

                    p {
                        font-size: 9px;
                    }
                }

                .chip {
                    font-size: 7px;
                    padding: 0.2rem 0.8rem;
                    border: 1px solid $pink-200;
                    border-radius: 10px;
                    width: fit-content;
                    margin-left: 0.5rem;
                    margin-top: 0.5rem;
                }
            }

            .rank-1 {
                width: 50%;
                height: 60%;
                top: 0;
                left: 0;
                background-color: $maroon-700;
            }

            .rank-2 {
                width: 50%;
                height: 50%;
                top: 0;
                right: 0;
                background-color: $purple-900;
            }

            .rank-3 {
                width: 50%;
                height: 50%;
                bottom: 0;
                right: 0;
                background-color: $purple-700;
            }

            .rank-4 {
                width: 30%;
                height: 40%;
                bottom: 0;
                left: 0;
                background-color: $purple-800;

                .chip {
                    margin: 0.3rem 0.5rem;
                }
            }

            .rank-5 {
                width: 20%;
                height: 40%;
                top: 60%;
                left: 30%;
                background-color: $purple-600;

                .chip {
                    margin: 0.3rem 0.5rem;
                }
            }
        }
    }
}