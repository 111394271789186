@import "/src/assets/scss/variables";
@import "/src/assets/scss/mixins";

.gpo-date-picker-wrapper {
    height: auto;
    position: relative;
    width: 100%;

    .gpo-date-picker {
        color: $body-color;
        font-family: $primary-font;
        font-weight: $weight-light;
        font-size: 1rem;
        background: $form-element-bg-color;
        height: calculateRem(44px);
        display: block;
        width: 100%;
        resize: vertical;
        transition: padding-left 300ms $ease-in-out-expo;
        line-height: 24px; //calculateRem($rhythm * 3);
        border: none;
        border-bottom: calculateRem(1px) solid $form-element-border-color;

        .react-date-picker__inputGroup__leadingZero,
        .react-daterange-picker__inputGroup__leadingZero {
            padding-left: 1px;
        }

        .react-date-picker__inputGroup__divider {
            color: $body-color;
        }

        .react-date-picker__inputGroup__input,
        .react-daterange-picker__inputGroup__input {
            &:invalid {
                background: transparent;
            }
        }

        .react-daterange-picker__inputGroup,
        .react-date-picker__inputGroup {
            padding: calculateRem(35px) calculateRem(10px) calculateRem(1px);

            &+.react-date-picker__button {
                margin-left: auto;
            }
        }

        .react-daterange-picker__inputGroup {
            min-width: auto;
            flex-grow: 0;

            &+.react-daterange-picker__button {
                margin-left: auto;
            }
        }

        &:hover:not(.react-date-picker--disabled):not(.react-daterange-picker--disabled),
        &.react-date-picker--open,
        &.react-daterange-picker--open {
            background-color: $form-element-hover-bg-color;

            .gpo-calendar {
                margin-top: calculateRem(1px);
            }

            .react-date-picker__calendar-button,
            .react-daterange-picker__calendar-button {
                top: calculateRem(0.5px);
            }

            &:after {
                content: '';
                bottom: calculateRem(-1px);
                height: calculateRem(2px);
                background-color: $form-element-border-color;
                position: absolute;
                left: 0;
                right: 0;
            }
        }

        &.gpo-date-picker-not-focused:not(.gpo-date-picker-has-value) {

            .react-date-picker__inputGroup,
            .react-daterange-picker__inputGroup,
            .react-daterange-picker__range-divider,
            .react-date-picker__inputGroup__divider {
                visibility: hidden;
            }
        }

        &.react-date-picker--disabled,
        &.react-daterange-picker--disabled {
            background: $form-element-disabled-bg-color;
            border: calculateRem(1px) solid $form-element-disabled-border-color;
            border-width: $form-element-disabled-border-width;
            color: $date-picker-disabled-color;
            cursor: not-allowed;

            input,
            button {
                cursor: not-allowed;
            }

            .react-date-picker__inputGroup__input,
            .react-daterange-picker__inputGroup__input,
            .react-date-picker__inputGroup__leadingZero,
            .react-daterange-picker__inputGroup__leadingZero {
                color: $date-picker-disabled-color;
                -webkit-text-fill-color: $date-picker-disabled-color;
            }

            .react-date-picker__inputGroup__divider,
            .react-date-picker__inputGroup__leadingZero {
                -webkit-text-fill-color: $date-picker-disabled-color;
            }

            .react-date-picker__inputGroup__divider {
                padding-right: calculateRem(3px);
            }

            .gpo-calendar-icon {
                fill: $date-picker-disabled-color;
            }

            &::placeholder {
                color: $date-picker-disabled-color;
            }
        }

        &.gpo-date-picker-invalid:not(.react-date-picker--disabled):not(.react-daterange-picker--disabled) {
            &:hover {
                &:after {
                    height: calculateRem(2px);
                    background-color: $error;
                }
            }

            &:after {
                content: '';
                bottom: calculateRem(-1px);
                height: calculateRem(1px);
                background-color: $error;
                position: absolute;
                left: 0;
                right: 0;
            }

            &.react-date-picker__calendar--open,
            &.react-daterange-picker__calendar--open {
                height: calculateRem(2px);
            }
        }

        .react-date-picker__wrapper,
        .react-daterange-picker__wrapper {
            border: none;
            height: 100%;
            margin: 0 calculateRem(-2px);
            padding-right: calculateRem(10px);
        }

        .react-date-picker__calendar--open,
        .react-daterange-picker__calendar--open {
            z-index: 99;
        }

        .gpo-calendar-icon {
            position: relative;
            z-index: 1;
            width: calculateRem(18px);
            height: calculateRem(18px);
            fill: $body-color;
        }

        .react-date-picker__inputGroup__input:invalid {
            background: transparent;
        }

        .react-date-picker__inputGroup__input,
        .react-date-picker__inputGroup__input::placeholder,
        .react-daterange-picker__inputGroup__input,
        .react-daterange-picker__inputGroup__input::placeholder {
            height: 1.5rem;
            font-size: calculateRem(13px);
            font-weight: $weight-light;
            color: $body-color;
        }

        .react-daterange-picker__range-divider {
            display: flex;
            align-items: center;
            padding-top: calculateRem(12px);
        }

        .react-date-picker__calendar-button,
        .react-daterange-picker__calendar-button {
            height: 100%;
            padding: 0;
            display: flex;
            align-items: center;
            right: calculateRem(3px);
        }

        .react-date-picker__inputGroup__year,
        .react-daterange-picker__inputGroup__year {
            min-width: calculateRem(32px);
        }

        .react-date-picker__inputGroup__day,
        .react-date-picker__inputGroup__month,
        .react-daterange-picker__inputGroup__day,
        .react-daterange-picker__inputGroup__month {
            min-width: calculateRem(17px);

            &.react-date-picker__inputGroup__input--hasLeadingZero,
            &.react-daterange-picker__inputGroup__input--hasLeadingZero {
                min-width: calculateRem(9px);
            }
        }

        &.gpo-date-picker-today-highlight .react-calendar__tile--now {
            background-color: $date-picker-today-mark;
            border-radius: 50%;
            color: green; //$date-picker-tile-now-color;
        }
    }

    .gpo-calendar {
        width: 100%;
        border-color: $date-picker-view-container-border-color;
        background-color: $date-picker-calendar-bg-color;

        .react-calendar__navigation {
            background: $date-picker-navigation-bg-color;
            margin-bottom: 0;
            height: calculateRem(46px);
            padding: 0 calculateRem(10px);

            button {
                min-width: calculateRem(42px);
                color: $white;

                &:enabled:hover,
                &:enabled:focus {
                    background: none;
                    box-shadow: inset 0 0 0 0.0714285714rem rgba(23, 119, 207, 0.5);
                }
            }
        }

        .react-calendar__viewContainer {
            background: $date-picker-view-container-color;
        }

        .react-calendar__navigation__label {
            font-family: $primary-font;
            font-size: calculateRem(12px);
        }

        .react-calendar__navigation__arrow {
            font-size: calculateRem(30px);
            height: calculateRem(42px);
        }

        .react-calendar__tile {
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: $primary-font;
            color: $date-picker-tile-color;
            margin-bottom: calculateRem(14px);
            padding: calculateRem(9px) calculateRem(7px);
            font-size: calculateRem(13.33px);

            &:enabled:hover {
                background-color: $date-picker-tile-active-hover-bg-color;
                color: $date-picker-tile-color;
            }
        }

        .react-calendar__month-view__weekdays {
            text-transform: none;
            padding: 0 calculateRem(14px);
        }

        .react-calendar__month-view__weekdays,
        .react-calendar__month-view__weekdays__weekday {
            font-weight: normal;
            background: $date-picker-navigation-bg-color;
            color: $date-picker-weekday-color;

            abbr {
                text-decoration: none;
            }
        }

        .react-calendar__tile--now {
            background: transparent;
        }

        .react-calendar__tile--active {
            background-color: $date-picker-tile-active-hover-bg-color;
        }

        .react-calendar__tile--active,
        .react-calendar__tile--hasActive {
            background-color: $date-picker-tile-active-hover-bg-color;
            color: $date-picker-tile-color;
            overflow: hidden;
        }

        .react-calendar__tile--rangeEnd,
        .react-calendar__tile--rangeStart {
            background-color: $date-picker-tile-active-bg-color;
            color: $date-picker-tile-active-color;

            &.react-calendar__tile--hover {
                background-color: $date-picker-tile-active-bg-color;
                color: $date-picker-tile-active-color;
            }
        }

        .react-calendar__tile--hover {
            background: $date-picker-tile-active-hover-bg-color;
            border-color: $date-picker-calendar-bg-color;
        }

        .react-calendar__century-view__decades,
        .react-calendar__decade-view__years,
        .react-calendar__month-view__days,
        .react-calendar__year-view__months {
            padding: calculateRem(14px) calculateRem(14px) 0 calculateRem(14px);
        }

        .react-calendar__year-view__months__month,
        .react-calendar__decade-view__years__year {
            &.react-calendar__tile--hasActive {
                background-color: $date-picker-tile-active-bg-color;
                color: $date-picker-tile-active-color;
            }
        }

        .react-calendar__month-view__days__day--neighboringMonth {
            color: $date-picker-neighboring-month-color;

            &.react-calendar__month-view__days__day--weekend {
                color: $date-picker-weekend-color;
            }
        }

        .react-calendar__navigation__label[disabled] {
            background-color: $date-picker-navigation-bg-color;
        }

        .react-calendar__navigation button:disabled {
            background-color: $grey-300;

        }

        .react-calendar__tile:disabled {
            background-color: $grey-300;
        }
    }

    .gpo-error-icon {
        height: calculateRem(20px);
        width: calculateRem(16px);
        fill: $error;
    }

    .react-daterange-picker__clear-button,
    .react-date-picker__clear-button {

        .react-daterange-picker__clear-button__icon,
        .react-date-picker__clear-button__icon {
            stroke: $white;
        }

        &:hover,
        &:focus {

            .react-daterange-picker__clear-button__icon,
            .react-date-picker__clear-button__icon {
                stroke: $body-color;
            }
        }
    }
}

.date-picker-form-field {
    position: relative;
    margin-bottom: 1rem;
}

.date-picker-label {
    font-family: $primary-font;
    font-weight: $weight-light;
    font-size: calculateRem(14px);
    width: calc(100% - 18px);
    line-height: calculateRem(22px);
    display: block;
    z-index: 1;
    pointer-events: none;
    transition: 200ms ease all;
    padding-bottom: 5px;
    color: $white;
}

.date-picker-form-field-date-input {
    .date-picker-label {
        position: absolute;
        top: calculateRem(14px);
        left: calculateRem(1px);
        padding-left: calculateRem(9px);
        transition: 200ms ease top, 200ms ease font-size;
        padding-bottom: calculateRem(8px);
    }
}

.date-picker-form-field-date-input.date-picker-form-field-focused,
.date-picker-form-field-date-input.date-picker-form-field-has-value {
    .date-picker-label {
        top: calculateRem(1px);
        font-size: calculateRem(12px);
        width: auto;
        z-index: 1;
        padding-bottom: 0;
        line-height: calculateRem(22px);
    }
}