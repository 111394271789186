@import "/src/assets/scss/variables";
@import "/src/assets/scss/mixins";

.label {
    font-size: 8px;
    font-weight: 300;
}

.value {
    font-size: 12px;
    font-weight: 700;
}