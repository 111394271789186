@import 'assets/scss/mixins';
@import 'assets/scss/variables';

.icon-button {
    cursor: pointer;
    outline: none;
    background: none;
    border: none;
    padding: 0;
    transition: box-shadow $ease-in-out-fast;
    color: $btn-icon-color;

    img {
        height: calculateRem(24px);
        width: calculateRem(24px);
        display: block;
    }

    &:hover {
        background-color: $btn-icon-hover-bg-color;
        color: $btn-icon-hover-color;
    }

    &:focus {
        background-color: $btn-icon-focus-bg-color;
        color: $btn-icon-focus-color;
    }

    &:disabled,
    &.disabled-state {
        cursor: auto;
        pointer-events: none;

        path {
            fill: $btn-icon-disabled-color;
        }

        &:hover {
            path {
                fill: $btn-icon-disabled-color;
            }
        }
    }

    &.icon-button-medium {
        padding: calculateRem(4px);
    }

    &.icon-button-large {
        padding: calculateRem(8px);
    }
}