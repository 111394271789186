@import "/src/assets/scss/variables";
@import "/src/assets/scss/mixins";

.toggle-switch-wrapper {
    display: flex;
    align-items: center;
}

.toggle-switch {
    position: relative;
    width: calculateRem(36px);
    height: calculateRem(16px);
    margin: 0 calculateRem(5px);

    input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    .toggle-switch-slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $toggle-switch-off-bg-color;
        transition: $toggle-switch-transition;
        border-radius: calculateRem(8px);

        &:before {
            position: absolute;
            content: '';
            height: calculateRem(20px);
            width: calculateRem(20px);
            left: 0;
            top: 50%;
            background-color: $toggle-switch-off-control-bg-color;
            transition: $toggle-switch-transition;
            border-radius: 50%;
            transform: translateX(0%) translateY(-50%);
            box-sizing: border-box;
            box-shadow: calculateRem(2px) 0 calculateRem(2px) 0 rgba(0, 0, 0, 0.1);
        }
    }

    input:checked+.toggle-switch-slider {
        background-color: $toggle-switch-on-bg-color;

        &:before {
            transform: translateX(calculateRem(18px)) translateY(-50%);
            background-color: $toggle-switch-on-control-bg-color;
        }
    }

    input:focus+.toggle-switch-slider,
    input:hover+.toggle-switch-slider {
        &:before {
            border: calculateRem(1px) solid $toggle-switch-hover-control-border-color;
        }
    }
}

.toggle-switch-checked {
    .toggle-switch-slider {
        &:before {
            box-shadow: -calculateRem(2px) 0 calculateRem(2px) 0 rgba(0, 0, 0, 0.1);
        }
    }

    .toggle-switch-on-text {
        font-weight: bold;
        color: $white;
    }

    .toggle-switch-off-text {
        font-weight: lighter;
        color: $white;
    }
}

.toggle-switch-on-text {
    font-size: calculateRem(12px);
    font-weight: lighter;
    color: $white;
}

.toggle-switch-off-text {
    font-size: calculateRem(12px);
    font-weight: bold;
    color: $white;
    text-align: right;
}

.toggle-switch-disabled {

    .toggle-switch-on-text,
    .toggle-switch-off-text {
        color: $grey-400;
    }

    .toggle-switch input {
        +.toggle-switch-slider {
            cursor: not-allowed;
            background-color: $toggle-switch-disabled-bg-color;
            border: calculateRem(1px) solid $toggle-switch-disabled-control-bg-color;

            &:before {
                border: none;
                background-color: $toggle-switch-disabled-control-bg-color;
                box-shadow: none;
            }
        }
    }
}

.toggle-switch-hidden-labels {

    .toggle-switch-off-text,
    .toggle-switch-on-text {
        display: none;
    }
}