@import "/src/assets/scss/variables";
@import "/src/assets/scss/mixins";

.session-timeout-container {
    background-color: $sidebar-bg;

    &__header {
        padding: 1rem;
        border-bottom: 1px solid $sidebar-border;
        color: white;
        font-weight: 400;

        img {
            margin-right: 0.3rem;
        }
    }

    &__content {
        width: 100vw;
        height: 90vh;
        @include flex(row, center, center);

        .content-wrapper {
            @include flex(column, flex-start, flex-start);
            gap: 1.5rem;
            padding: 2rem;

            &__logo {
                @include flex();
                width: 100%;
            }

            &__message {
                @include flex(column, center, center);
                width: 100%;
                gap: 2rem;
                color: #FAFAFC;
                font-weight: 400;

                .message-title {
                    span {
                        font-size: calculateRem(28px);
                    }
                }

                .message-detail {
                    margin-top: 1rem;

                    span {
                        font-size: calculateRem(14px);
                    }
                }
            }

            &__action {
                @include flex();
                width: 100%;
                margin-top: -0.5rem;

                button {
                    background-color: $yellow-400;
                    border-color: transparent;
                    color: $grey-500;
                    font-weight: 700;
                    padding: 0.75rem 2.2rem;
                    cursor: pointer;
                }
            }
        }
    }
}