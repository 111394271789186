@import "/src/assets/scss/variables";
@import "/src/assets/scss/mixins";

.user-review-filter {
    position: absolute;
    background-color: darken($grey-600, 2%);
    width: 25rem;
    top: 0;
    z-index: 1;
    right: 0;
    animation: fade .3s ease;

    &__header {
        @include flex(row, space-between, center);
        padding: 1rem;
        border-bottom: 1px solid $sidebar-border;

        p {
            color: $white;
            font-size: calculateRem(14px);
        }

        img {
            @include size(1.5rem, 1.5rem);
            cursor: pointer;
        }
    }

    &__body {
        margin: 1rem 0;
    }

    .top>h5 {
        margin-bottom: 1rem;
    }

    footer {
        padding: .75rem 1rem;
        border-top: .06rem solid lighten($grey-450, 5%);
        @include flex(row, flex-start, center);
        gap: .5rem;

        button:disabled,
        button[disabled] {
            cursor: not-allowed;
            pointer-events: visible;
        }
    }

    &__rating {
        margin: 1rem 10px;
        padding: 0px 12px;
        border-bottom: 1px solid $sidebar-border;

        &__header {
            @include flex(row, space-between, center);
            margin-bottom: 1rem;

            p {
                color: $white;
                font-size: calculateRem(14px);
            }

            img {
                cursor: pointer;
            }
        }

        &--expand {
            @include size(0.8rem, 0.8rem);
        }

        &--collapse {
            @include size(1.2rem, 1.2rem);
        }


        &__option {
            margin-bottom: 1rem;
            @include flex(row, space-between, center);

            div {
                cursor: pointer;
            }

            p {
                color: $grey-700;
                font-size: calculateRem(12px);
            }

            label {
                display: flex;
                align-items: center;
                position: relative;
                font-size: 0.75rem;
                color: $white;
            }

            input {
                display: none;
            }

            label>span {
                @include square(1.3rem);
                display: flex;
                justify-content: center;
                border: 0.06rem solid $white;
                margin-right: 0.5rem;
                transition: all 0.3s;
            }

            input:checked+label>span {
                border: 0.06rem solid transparent;
                animation: bounce 250ms;
            }

            input:checked+label>span::before {
                content: "";
                position: absolute;
                top: 0.5rem;
                left: 4px;
                border-right: 0.1rem solid transparent;
                border-bottom: 0.1rem solid transparent;
                transform: rotate(45deg);
                transform-origin: 0% 100%;
                animation: checked-box 125ms 250ms forwards;
            }

            input:checked+label>span {
                background: $grey-500;
            }

            @keyframes checked-box {
                0% {
                    width: 0;
                    height: 0;
                    border-color: #fff;
                    transform: translate(0, 0) rotate(45deg);
                }

                33% {
                    width: 4px;
                    height: 8px;
                    border-color: #fff;
                    transform: translate(0, 0) rotate(45deg);
                }

                100% {
                    width: 6px;
                    height: 10px;
                    border-color: #fff;
                    transform: translate(0, -8px) rotate(45deg);
                }
            }

            @keyframes bounce {
                0% {
                    transform: scale(1);
                }

                33% {
                    transform: scale(0.7);
                }

                100% {
                    transform: scale(1);
                }
            }
        }

    }

    &__comments {
        margin: 1rem 22px;

        p {
            color: $white;
            font-size: calculateRem(13px);
        }
    }

    &__date-range {
        border-bottom: 1px solid $sidebar-border;
        border-top: 1px solid $sidebar-border;
        margin: 0 12px;
        padding: 1rem 0;

        .date-picker-form-field {
            margin: 0 10px;
        }
    }

    &__country {
        margin: 0 12px;
        padding: 1rem 0 1.5rem 0;

        .multi-select__form-field {
            margin: 0 10px;
        }
    }
}