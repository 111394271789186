@import "/src/assets/scss/variables";
@import "/src/assets/scss/mixins";

.user-eng-field-loader {
    background-color: $app-bg;
    position: relative;
    z-index: 99;
    width: 100%;

    &--header {
        padding: .3rem 0;
        @include flex(column, flex-start, baseline);
        gap: 1rem;

        .value {
            width: 70%;
            height: 0.6rem;
            position: relative;
            display: block;
            border-radius: 1rem;
            overflow: hidden;

            &::before {
                position: absolute;
                content: "";
                @include square(100%);
                background-image: linear-gradient(to right,
                        #656871 0%,
                        #888b94 20%,
                        #656871 40%,
                        #656871 100%);
                background-repeat: no-repeat;
                background-size: 450px 400px;
                animation: shimmer 1.5s linear infinite;
            }
        }

        @keyframes shimmer {
            0% {
                background-position: -450px 0;
            }

            100% {
                background-position: 450px 0;
            }
        }
    }
}