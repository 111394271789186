@import 'assets/scss/mixins';
@import 'assets/scss/variables';

.multi-select {

    &--disabled {
        .multi-select__trigger {
            cursor: not-allowed;
        }

        .multi-select__form-field__label,
        .multi-select__input-text {
            color: $grey-250;
        }

        .multi-select__container {
            background-color: $grey-500;
            border-bottom: 1px solid $grey-300;
        }

        .multi-select__arrow {
            opacity: 0.3;
        }
    }

    &__form-field {
        position: relative;
        margin-bottom: 0.8571428571rem;

        &__label {
            color: $white;
            position: absolute;
            z-index: 1;
            top: 12px;
            left: 8px;
            font-size: calculateRem(12px);
            transition: 200ms ease top, 200ms ease font-size;

            &--active {
                top: 2px;
                font-size: calculateRem(10px);
            }
        }
    }

    &__error {
        color: $file-uploader-invalid-font-color;
        text-align: start;
        margin-top: 0.5rem;
        font-size: calculateRem(11px);
    }

    &__container {
        background-color: #222222;
        border-bottom: 1px solid $grey-200;
        position: relative;
    }

    &__arrow {
        height: calculateRem(12px);
        width: calculateRem(12px);
    }

    &__trigger {
        @include flex(row, space-between, center);
        background: none;
        min-height: 2.5rem;
        border: none;
        position: relative;
        text-align: left;
        width: 100%;
        cursor: pointer;
    }

    &__input-text {
        width: calc(100% - 2.15rem);
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        color: $white;
        padding-top: calculateRem(14px);
        font-family: $primary-font;
    }

    &__options-wrapper {
        width: 100%;
        position: absolute;
        top: 2.6rem;
        z-index: 2;

        &--top {
            top: unset;
            bottom: 2.5rem;
        }
    }

    &__search {
        width: 100%;
        @include flex(row, flex-start, center);
        border-bottom: 1px solid $grey-200;
        background-color: $dropbg;
        padding: 0.5rem;

        img {
            height: calculateRem(12px);
            width: calculateRem(12px);
        }

        input {
            background-color: inherit;
            height: calculateRem(20px);
            width: 100%;
            border: none;
            color: $white;
            font-family: $primary-font;
        }

        &--close-btn {
            cursor: pointer;
        }
    }

    &__options {
        width: 100%;
        display: block;
        max-height: 9rem;
        overflow-y: auto;
        background-color: $dropbg;
        // padding: 0.5rem 1rem;
        @include scrollbars(0.4rem, lighten($grey-450, 25%), $grey-450);
        color: $white;
        font-size: calculateRem(12px);

        input,
        label {
            cursor: pointer;
        }

        label {
            width: 90%;
        }

        .no-data {
            padding: 0.5rem 0.8rem;
        }
    }

    &__option,
    &__select-all--wrapper {
        padding: 0.8rem 0.2rem;
        max-height: 2.5rem;
        @include flex(row, flex-start, center);

        &:hover {
            background-color: $grey-600;
            color: $yellow-400;
        }

        input {
            cursor: pointer;
            display: none;
        }

        label {
            margin-left: 0.5rem;
            -webkit-line-clamp: 2;
            cursor: pointer;
            // to show countries in 2 lines with ellipsis
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
            position: relative;
            text-overflow: ellipsis;
            @include flex(row, flex-start, center);
            gap: 0.7rem;
            width: 100%;
        }

        label>span {
            position: relative;
            @include square(1.2rem);
            @include flex(row, center, stretch);
            border: 0.06rem solid $white;
            transition: all 0.3s;
        }

        input:checked+label>span {
            border: 0.06rem solid $white;
        }

        input:checked+label>span::before,
        input.all-selected:checked+label>span::before {
            content: "";
            position: absolute;
            top: 0.3rem;
            left: 3px;
            border-right: 0.1rem solid transparent;
            border-bottom: 0.1rem solid transparent;
            transform: rotate(45deg);
            transform-origin: 0% 100%;
            width: 4px;
            height: 10px;
            border-color: #000;
            transform: translate(0, -6px) rotate(45deg);
        }

        input:checked+label>span {
            background: $white;
        }
    }

    &__select-all {
        width: 100%;
        @include flex(row, flex-start, center);
        border-bottom: 1px solid #5a5a5b;
        background-color: #282836;
        color: $white;
        font-size: calculateRem(12px);

        &--wrapper {
            width: 100%;
            color: $white;
            font-size: calculateRem(12px);

            label {
                width: 100%;
            }

            input.few-selected:checked+label>span::before {
                content: "";
                position: absolute;
                border: none;
                width: 0.8rem;
                height: unset;
                top: 50%;
                transform: translate(0, -50%);
                transition: all .3s;
                border-top: 0.1rem solid black;
                animation: none;
            }
        }
    }
}