@import "/src/assets/scss/variables";
@import "/src/assets/scss/mixins";

.user-feedback-loader {
  background-color: $app-bg;
  position: absolute;
  z-index: 99;
  @include size(100%, 100%);
  margin: -1.5rem -2rem;

  &__container {
    padding: 0.75rem;
    border-right: 0.06rem solid $sidebar-border;
    height: 100%;

    &--header {
      margin: 0 -.75rem;
      padding: .75rem;
      @include flex(column, flex-start, baseline);
      gap: 1rem;

      .label,
      .content-line-1,
      .content-line-2,
      .rating-row {
        width: 30%;
        height: 0.6rem;
        position: relative;
        display: block;
        border-radius: 1rem;
        overflow: hidden;
      }

      .content-line-1,
      .content-line-2 {
        width: 50%;
      }

      .rating-row__wrapper {
        margin-top: 1rem;
        width: 100%;
        @include flex(column, flex-start, flex-start);
        gap: 2rem;
      }

      .rating-row {
        width: 80%;
        height: 0.4rem;
      }
    }

    &--header p::before {
      position: absolute;
      content: "";
      @include square(100%);
      background-image: linear-gradient(to right,
          #656871 0%,
          #888b94 20%,
          #656871 40%,
          #656871 100%);
      background-repeat: no-repeat;
      background-size: 450px 400px;
      animation: shimmer 1.5s linear infinite;
    }

    @keyframes shimmer {
      0% {
        background-position: -450px 0;
      }

      100% {
        background-position: 450px 0;
      }
    }
  }
}