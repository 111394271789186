@import 'assets/scss/mixins';
@import 'assets/scss/variables';

.progress-bar-wrapper {
    display: flex;
    gap: 1rem;

    &:not(.progress-bar-wrapper-circle) {
        flex-direction: column;
    }

    &.progress-bar-wrapper-circle {
        justify-content: center;
        align-items: center;
        width: $progress-bar-circle-size;
        height: $progress-bar-circle-size;
    }

    .progress-bar-label {
        font-size: calculateRem(12px);
        color: $progress-bar-label-color;
    }

    .progress-bar-label-hidden {
        clip: rect(1px, 1px, 1px, 1px);
        clip-path: inset(50%);
        height: 1px;
        width: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
    }

    .progress-bar {
        display: inline-block;
        height: calculateRem(4px);
        width: 100%;
        background-color: $progress-bar-bg-color;
        position: relative;
        overflow: hidden;

        &.progress-bar-indeterminate {
            .progress {
                position: relative;
                width: 25%;
                animation: translate 1.5s infinite linear;
            }
        }

        &.progress-bar-circle {
            background-color: transparent;
            height: $progress-bar-circle-size;
            width: $progress-bar-circle-size;
            position: absolute;

            &.progress-bar-indeterminate {
                animation: rotation 1s infinite linear;
            }
        }
    }

    .progress {
        top: 0;
        bottom: 0;
        position: absolute;
        left: 0;
        right: 0;
        background-color: $progress-bar-color;
        height: calculateRem(4px);

        &.progress-error {
            background-color: $error;
        }
    }
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(359deg);
    }
}

@keyframes translate {
    0% {
        left: -25%;
    }

    20% {
        left: 0;
    }

    40% {
        left: 33%;
    }

    60% {
        left: 66%;
    }

    80% {
        left: 100%;
    }

    100% {
        left: 125%;
    }
}