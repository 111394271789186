@import "/src/assets/scss/variables";
@import "/src/assets/scss/mixins";
@import "/src/assets/scss/typography";

.dashboard-container {
    height: 100%;
    background-color: $sidebar-bg;
    position: relative;
    z-index: 99;

    &__header {
        @include flex(row, space-between, center);
        border-bottom: 0.06rem solid $sidebar-border;
        border-right: 0.06rem solid $sidebar-border;
        background-color: $sidebar-bg;

        &--left {
            @include flex();
            gap: 2rem;
        }

        &--title {
            @include flex(column, flex-start, flex-start);
            gap: 0.3rem;
            padding: 1.8rem;
            padding-right: 0.5rem;
            margin-right: 1rem;

            h2 {
                color: $nav-bg;
            }

            .last-update {
                color: $grey-300;
                font-size: 12px;
                font-weight: 300;
            }
        }


        &--actions {
            @include flex(row, flex-start, center);
            margin-right: 1.8rem;
            gap: 1rem;
            font-size: 12px;

            .select-container {
                @include flex();
                color: $grey-250;

                .country {
                    &-label {
                        font-weight: 300;
                    }

                    &-select {
                        margin-bottom: 0;

                        .multi-select {
                            &__form-field__label--active {
                                //hiding label when value selected from dropdown
                                display: none;
                            }

                            &__container {
                                background-color: transparent;
                                border-bottom: none;
                                width: 5rem;
                            }

                            &__search,
                            &__options,
                            &__select-all {
                                background-color: #282828;
                            }

                            &__options {
                                @include scrollbars(0.4rem, $grey-300, $table-bg);

                                &-wrapper {
                                    width: 300%;
                                    left: -200%;
                                    z-index: 99;
                                }
                            }

                            &__option {
                                label {
                                    width: 100%;

                                    &>span {
                                        min-width: 9%;
                                    }
                                }
                            }

                            &__trigger {
                                width: auto;
                                min-width: 100%;
                                max-width: 100%;
                            }

                            &__input-text {
                                padding-top: 0;
                                width: unset;
                                margin: auto;
                            }

                            &__error {
                                position: absolute;
                                left: -60%;
                                margin-top: 0;
                                white-space: nowrap;
                            }
                        }
                    }
                }

            }

            .date-picker {
                @include flex();
                color: $grey-250;

                &__label {
                    font-weight: 300;
                }

                &__wrapper {
                    position: relative;

                    .custom-date-picker {
                        height: 3.75rem;

                        .gpo-calendar {
                            border: none;
                            position: relative;
                            right: 28%;
                        }

                        * {
                            font-family: $ey-font-light;
                        }

                        .react-calendar__viewContainer,
                        .react-calendar__navigation,
                        .react-calendar__month-view__weekdays,
                        .react-calendar__month-view__weekdays__weekday {
                            background: #282828;
                        }

                        .react-daterange-picker__inputGroup {
                            padding: 3.3rem 0 0 0.5rem;
                            margin-right: 0.5rem;
                            font-family: $ey-font-light;
                            font-family: 0.8rem;
                        }

                        .react-daterange-picker__calendar-button {
                            margin: 1rem 0 0 0;
                            height: calculateRem(12px);
                            width: calculateRem(12px);

                            .calendar__arrow {
                                height: 100%;
                                width: 100%;
                            }
                        }

                        .react-daterange-picker__clear-button {
                            display: none;
                        }

                        &:hover:not(.react-date-picker--disabled):not(.react-daterange-picker--disabled),
                        &.react-date-picker--open,
                        &.react-daterange-picker--open {
                            background-color: transparent;

                            &:after {
                                height: 0;
                            }
                        }
                    }

                    .action-btns {
                        position: absolute;
                        @include flex(column, space-between, stretch);
                        gap: 0.5rem;
                        font-family: $ey-font-light;
                        background: #282828;
                        padding: 1.2rem 1.2rem 1.2rem 0.6rem;
                        inset: 80% 135% auto auto;
                        width: 8.6rem;
                        height: 22.6rem;
                        z-index: 99;

                        .btn {
                            padding: 11px 12px;
                            border: none;
                            background-color: #333333;
                            color: #FFF;
                        }
                    }
                }

                .date-picker-form-field {
                    .react-daterange-picker {
                        background: transparent;
                        border-bottom: none;
                    }
                }
            }
        }

        &--right {
            @include flex();
            gap: 1rem;
            margin-right: 1rem;

            &__action-btn {
                @include flex(row, flex-start, center);
                color: $white;
                background-color: transparent;
                border: none;
                gap: 0.3rem;
                cursor: pointer;

                p {
                    font-size: calculateRem(12px);
                }
            }
        }
    }

    &__body {
        color: #FFFFFF;
        padding: 1rem;
        overflow-y: auto;
        max-height: calc(100vh - 5.4rem);

        .custom-tooltip {
            background-color: #FFF;
            color: #000;
            padding: 0.5rem;
            font-size: 10px;
            font-weight: 300;
        }

        .bot-performance {
            margin-bottom: 1rem;

            &-row {
                @include flex(row, space-between, stretch);
                gap: 2rem;
                margin-top: 0.5rem;

                &:last-child {
                    margin-top: 2rem;
                }

                @include for-portrait-mode {
                    flex-direction: column;
                }

                @include for-max-width-1100px {
                    flex-direction: column;
                }
            }

            &-container {
                position: relative;
                @include flex(row, space-around, center);
                border: 1px solid $grey-450;
                background-color: $app-bg;
                border-radius: 4px;
                width: calc(50% - 1rem);
                min-height: 15rem;

                .label {
                    font-size: 8px;
                    font-weight: 300;
                }

                .value {
                    font-size: 12px;
                    font-weight: 700;
                }

                // overrides for sentiment distribution pie chart
                .sentiment-pie {
                    .recharts-responsive-container {
                        position: relative;
                        top: 12px;
                    }

                    .label {
                        transform: translateY(-8px);
                    }

                    .value {
                        transform: translateY(-6px);
                    }
                }

                @include for-portrait-mode {
                    width: 100%;
                }

                @include for-max-width-1100px {
                    width: 100%;
                }
            }

            .line-chart-container {
                padding: 1.5rem 1.5rem 0.5rem 0;
                width: 65%;

                &__no-data {
                    padding: none;
                    text-align: center;
                }
            }

            .bar-chart-container {
                padding: 1.5rem 1.5rem 0.5rem 0;
                width: 65%;
            }
        }

        .user-feedback {
            &-container {
                border: 1px solid #3a3a4a;
                margin-top: 0.5rem;
                background-color: #1a1a1a;
                border-radius: 4px;
                padding: 1.5rem 2rem;
                min-height: 15rem;
                position: relative;

                .no-data {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%)
                }
            }

            .ratings-info {
                @include flex(column, center, flex-start);
                gap: 0.5rem;

                &__heading {
                    font-size: 14px;
                    color: $grey-300;
                }

                &__rate {
                    font-size: 12px;
                    @include flex();
                    gap: 0.5rem;
                }

                &__description {
                    @include flex();
                    font-size: 10px;
                    color: $grey-300;

                    span {
                        @include flex();
                        height: 12px;
                        cursor: pointer;

                        img {
                            height: 100%;
                        }
                    }
                }
            }

            .ratings-container {
                margin-top: 2rem;

                &__row {
                    @include flex(row, flex-start, center);
                    gap: 1.5rem;
                    margin-bottom: 1rem;

                    &--label,
                    &--value {
                        font-size: 10px;
                        font-weight: 700;
                    }

                    &--outerline {
                        width: 80%;
                        height: 4px;
                        background-color: $lighter-black;
                        transition: background-color 0.5s;
                    }

                    &--innerline {
                        height: 4px;
                        background-color: $yellow-400;
                        transition: background-color 0.5s;
                        border-radius: 2px;
                    }
                }
            }

            @include for-portrait-mode {
                margin-bottom: 2rem;
            }
        }
    }
}