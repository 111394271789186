@import "/src/assets/scss/variables";
@import "/src/assets/scss/mixins";

.speedometer-container {
    @include flex(column, center, center);
    padding: 1.5rem;
    position: relative;
    width: 35%;

    .chart-title {
        @include flex();
        gap: 0.3rem;
        font-size: 14px;
        white-space: nowrap;
        position: absolute;
        top: 7%;
        left: 50%;
        transform: translate(-40%, -7%);
        z-index: 1;

        img {
            height: 14px;
        }
    }

    .speedometer {
        padding-top: 2rem;

        //to hide segment label
        .label> :nth-child(2) {
            display: none;
        }
    }
}