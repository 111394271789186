@import "/src/assets/scss/variables";
@import "/src/assets/scss/mixins";

.question-details-filter {
    position: absolute;
    background-color: #2E2E2E;
    width: 25rem;
    top: 20vh;
    z-index: 1;
    right: 2.5rem;
    animation: fade .3s ease;
    box-shadow:-2px 2px rgba(0, 0, 0, 0.25);

    &__header {
        @include flex(row, space-between, center);
        padding: 1rem;
        border-bottom: 1px solid $sidebar-border;

        p {
            color: $white;
            font-size: calculateRem(14px);
        }

        img {
            @include size(1.5rem, 1.5rem);
            cursor: pointer;
        }
    }

    &__body {
        @include flex(column,flex-start,flex-start);
        max-height:calc(100vh - 18rem);
        overflow:auto;
        @include scrollbars(0.25rem, lighten($grey-450, 25%), $grey-450);
         .body-item{
            padding: 1rem 1.5rem;
            border-bottom: 0.06rem solid #454558;
            width: 100%;
            .gpo-date-picker-wrapper .gpo-date-picker{
                background-color: #282828 !important;
            }
            .multi-select__container,
            .multi-select__search,
            .multi-select__options,
            .gpo-date-picker-wrapper .gpo-date-picker ,
            .gpo-date-picker-wrapper .gpo-calendar .react-calendar__navigation,
            .gpo-date-picker-wrapper .gpo-calendar .react-calendar__viewContainer,
            .gpo-date-picker-wrapper .gpo-calendar .react-calendar__month-view__weekdays, .gpo-date-picker-wrapper .gpo-calendar .react-calendar__month-view__weekdays__weekday{
                background-color: #282828 !important; 
            }
            .multi-select__option:hover{
                background-color:#3B3B3B !important
            }
            
        }
        .document-filter {
            @include grid(30% 1fr, center, 0);
            span {
              color: $white;
              line-height: 1rem;
              font: {
                size: 0.9rem;
              }
            }
            span.hasValue{
                left: 0rem;
                position: absolute;
                top: -0.2rem;
                background: #282828 !important;
                width: 100%;
                padding: 0.5rem;
                font-size: 0.65rem;
            }
            textarea,
            input,
            button {
              border-radius: 0.35rem;
              background-color: lighten($sidebar-bg, 5%);
              border: none;
              outline: none;
              padding: 0.8rem;
              color: $white;
              width: 100%;
              font: {
                family: var(--primary-font);
                size: 0.8rem;
              }
            }
            textarea {
              height: 5rem;
              @include scrollbars(0.25rem, lighten($grey-450, 25%), $grey-450);
              resize: none;
            }
            .dropdown {
              position: relative;
              width: 21.5vw;
              .dropdown-value{
                top: 0.7rem;
                position: relative;
                @include flex(row,space-between,center);
                width: 100%;
              }
              button {
                background: #282828 !important;
                border-bottom: 1px solid $white;
                border-radius: 0;
                width: 100%;
                text-align: left;
                @include flex(row,flex-start,center);
                gap : 1rem;
                grid-gap: 1rem;
                //height : 3rem;
                // img {
                //   position: absolute;
                //   right: 0.75rem;
                //   top: 1rem;
                //   @include square(0.5rem);
                //   cursor: pointer;
                //   transition: transform 0.3s ease;
                //   &.active {
                //     transform: rotate(180deg);
                //   }
                // }
                img.arrow{
                  margin-left:auto;
                  &.active {
                    transform: rotate(180deg);
                  }
                }
                img.pdf{
                  width:1.1rem;
                  height:1.1rem;
                }
              }
              ul {
                background-color: lighten($sidebar-bg, 5%);
                position: absolute;
                width: 100%;
                z-index: 10;
                animation: dropdown 0.4s ease;
                top: 3rem;
                overflow-y: auto;
                height: 15rem;
                overflow-x: hidden;
                @include scrollbars(0.25rem, lighten($grey-450, 25%), $grey-450);
                li {
                  padding: 0.75rem;
                  list-style: none;
                  @include flex(row, flex-start, center);
                  color: $white;
                  transition: all .3s ease;
                  cursor: pointer;
                  font: {
                    size: 0.8rem;
                  }
                  img {
                    @include square(1.1rem);
                    margin-right: 0.5rem;
                  }
                  &:not(:last-child) {
                    border-bottom: 0.06rem solid lighten($sidebar-bg, 11%);
                  }
                  &:hover {
                    background-color: lighten($sidebar-bg, 3%);
                  }
                }
              }
            }
            &:not(:last-child) {
              margin-bottom: 1rem;
            }
          }
    }

    .top>h5 {
        margin-bottom: 1rem;
    }

    footer {
        padding: .75rem 1rem;
        @include flex(row, flex-start, center);
        gap: .5rem;
    }

    &__rating {
        margin-bottom: 1.5rem;

        &__header {
            @include flex(row, space-between, center);
            margin-bottom: 1rem;

            p {
                color: $white;
                font-size: calculateRem(14px);
            }

            img {
                @include size(1.5rem, 1.5rem);
            }
        }

        &__option {
            margin-bottom: 1rem;
            @include flex(row, space-between, center);

            div {
                cursor: pointer;
            }

            p {
                color: $grey-700;
                font-size: calculateRem(12px);
            }

            label {
                display: flex;
                align-items: center;
                position: relative;
                font-size: 0.75rem;
                color: $white;
            }

            input {
                display: none;
            }

            label>span {
                @include square(1.3rem);
                display: flex;
                justify-content: center;
                border: 0.06rem solid $white;
                margin-right: 0.5rem;
                transition: all 0.3s;
            }

            input:checked+label>span {
                border: 0.06rem solid transparent;
                animation: bounce 250ms;
            }

            input:checked+label>span::before {
                content: "";
                position: absolute;
                top: 0.5rem;
                left: 4px;
                border-right: 0.1rem solid transparent;
                border-bottom: 0.1rem solid transparent;
                transform: rotate(45deg);
                transform-origin: 0% 100%;
                animation: checked-box 125ms 250ms forwards;
            }

            input:checked+label>span {
                background: $grey-500;
            }

            @keyframes checked-box {
                0% {
                    width: 0;
                    height: 0;
                    border-color: #fff;
                    transform: translate(0, 0) rotate(45deg);
                }

                33% {
                    width: 4px;
                    height: 8px;
                    border-color: #fff;
                    transform: translate(0, 0) rotate(45deg);
                }

                100% {
                    width: 6px;
                    height: 10px;
                    border-color: #fff;
                    transform: translate(0, -8px) rotate(45deg);
                }
            }

            @keyframes bounce {
                0% {
                    transform: scale(1);
                }

                33% {
                    transform: scale(0.7);
                }

                100% {
                    transform: scale(1);
                }
            }
        }



    }
}

