@import "/src/assets/scss/variables";
@import "/src/assets/scss/mixins";

.user-review {
    background-color: #222;
    height: 100%;

    &__container {
        height: 100%;
    }

    &__header {
        padding: 1.2rem 1.77rem;
        border-bottom: 0.06rem solid $sidebar-border;
        border-right: 0.06rem solid $sidebar-border;
        background-color: $sidebar-bg;
        @include flex(row, space-between, center);

        &__left {
            align-self: flex-end;

            h2 {
                color: $nav-bg;
            }

            p {
                margin-top: 0.25rem;
                color: $grey-300;
                font-size: 12px;
                font-weight: 300;
            }
        }

        &__right {
            @include flex(row, flex-end, center);
            gap: 0.2rem;

            button {
                background-color: $white;
                border: 0.06rem solid $white;
                font-weight: bold;
                padding: 0.5rem 1.25rem;
                color: $black;
                cursor: pointer;
                @include flex(row, center, center);
                gap: 0.5rem;
            }
        }
    }

    &__body {
        padding: 1rem;
        margin: 1.5rem;
        background-color: $black-250;
        border: 1px solid $grey-450;
        border-radius: 0.5rem;
        position: relative;
        height: calc(100vh - 7rem);
    }

    &__table {
        position: relative;

        .ag-theme-alpine {
            .ag-root-wrapper-body {
                max-height: calc(100vh - 21rem) !important;
            }

            .ag-body-viewport {
                overflow-y: auto;
                @include scrollbars(0.4rem, lighten($grey-450, 25%), $grey-450);
            }

            .ag-layout-auto-height .ag-center-cols-container,
            .ag-layout-auto-height .ag-center-cols-clipper {
                min-height: 2rem;
            }

            .ag-cell {
                .star-rating {
                    padding-top: calculateRem(12px);
                }
            }
        }
    }

    &__selected-filter {
        @include flex(row, space-between, center);
        color: $white;
        gap: 0.5rem;
        text-wrap: nowrap;

        &__wrapper {
            @include flex(row, space-between, center);
            gap: 0.5rem;
            overflow-x: auto;
            padding-bottom: 0.7rem;
            @include scrollbars(0.4rem, lighten($grey-450, 25%), $grey-450);
        }

        &__label {
            font-size: calculateRem(12px);
        }

        &__clear {
            font-size: calculateRem(11px);
            text-wrap: nowrap;

            &:hover {
                cursor: pointer;
                text-decoration: underline;
            }
        }

        &__refresh-btn {
            @include flex(row, flex-start, center);
            color: $white;
            background-color: transparent;
            border: none;
            gap: 0.3rem;
            cursor: pointer;

            p {
                font-size: calculateRem(12px);
            }
        }
    }

    &__chip {
        display: flex;
        align-items: center;
        padding: calculateRem(4px) calculateRem(6px);
        border: calculateRem(2px) solid $white;
        border-radius: 1rem;
        gap: 0.3rem;
        text-wrap: nowrap;

        p {
            font-size: calculateRem(11px);
        }

        img {
            cursor: pointer;
        }
    }

    &__total-count {
        @include flex(row, space-between, center);
        margin-bottom: 0.5rem;

        div {
            @include flex(row, center, center);
            width: 30%;
            color: $white;
            background-color: $grey-500;
            padding: 0.8rem 2rem;

            img {
                @include square(1.3rem);
            }
        }

        &__label {
            font-size: 0.8rem;
            color: $grey-300;
            margin-left: 0.5rem;
            margin-right: 1.5rem;
        }

        &__value {
            font-size: calculateRem(12px);
        }
    }

    &__action-panel {
        @include flex(row, space-between, center);
        background-color: #2b2b2f;
        padding: 0.75rem;
        gap: 0.5rem;
        margin-bottom: 0.75rem;

        &__export-btn {
            @include flex(row, flex-start, center);
            color: $white;
            background-color: #2b2b2f;
            border: none;
            gap: 0.3rem;
            cursor: pointer;

            p {
                font-size: calculateRem(12px);
            }
        }

        &__right {
            @include flex(row, flex-start, center);
            gap: 1rem;
        }

        &__filter-btn {
            position: relative;
            align-items: center;
            display: flex;
            cursor: pointer;

            span {
                color: $white;
                font-size: calculateRem(12px);
                margin-left: 0.3rem;
                margin-right: 1rem;
            }

            &::after {
                content: '';
                height: 100%;
                width: 1px;
                background-color: #3a3a4a;
                right: 0;
                top: 0;
                position: absolute;
            }
        }

        &__search {
            background-color: #2b2b2f;
            @include flex(row, space-between, center);
            gap: 0.5rem;

            img {
                @include square(1rem);
                cursor: pointer;
            }

            input {
                width: 20rem;
                color: $white;
                background-color: transparent;
                border: none;
                outline: none;

                &::placeholder {
                    font-size: 0.75rem;
                    color: $white;
                }
            }
        }
    }

    &__comment-panel {
        @include flex(column, flex-start, flex-start);
        background-color: $bg-modal;
        width: 22rem;
        height: 57vh;
        position: absolute;
        z-index: 2;
        right: 0;
        top: 0;
        color: $white;
        box-shadow: -2px 2px rgb(0, 0, 0, 0.25);

        &__header {
            @include flex(row, space-between, center);
            width: 100%;
            padding: 0.75rem;
            font-size: 0.875rem;
            border-bottom: 1px solid $grey-450;

            img {
                cursor: pointer;
                width: calculateRem(18px);
                height: calculateRem(18px);
            }
        }

        &__body {
            width: 100%;
            padding: 0.75rem;
            font-size: calculateRem(12px);
            word-break: break-word;
        }

    }

    &__export-progress {
        padding: calculateRem(20px) 5.5rem;

        .progress-bar-wrapper {
            margin: 4rem 0;
        }
    }
}