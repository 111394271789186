@import 'assets/scss/mixins';
@import 'assets/scss/variables';

.modal-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 50px;
    background: $modal-overlay-bg-color;
    z-index: 1000;
}

.modal-content {
    background: $modal-bg-color;
    flex-direction: column;
    max-height: 90%;
    max-width: calculateRem(1024px);
    overflow-y: auto;
    display: flex;
    box-shadow: $modal-box-shadox;

    &.modal-slideout {
        width: 50%;
        transform: translateX(0);
        position: absolute;
        right: 0;
        max-height: 100%;
        min-width: calculateRem(380px);
        max-width: calculateRem(580px);
        height: 100%;
        animation-name: modal-slidout-animation;
        animation-duration: 0.25s;

        .modal-footer {
            margin-top: auto;
        }
    }
}

.modal {
    min-width: calculateRem(400px);
    width: inherit;

    &-size-sm {
        max-width: calculateRem(400px);
    }

    &-size-md {
        max-width: calculateRem(540px);
    }

    &-size-lg {
        max-width: calculateRem(680px);
    }

    &-size-xl {
        max-width: calculateRem(820px);
    }

    &-takeover {
        max-height: 100%;
        max-width: 100%;
        width: 100vw;
        height: 100vh;

        .modal-footer {
            margin-top: auto;
        }
    }

    &-overlay-takeover {
        padding: 0;
    }
}

@keyframes modal-slidout-animation {
    from {
        transform: translateX(100%);
    }

    to {
        transform: translateX(0);
    }
}