@import "/src/assets/scss/variables";
@import "/src/assets/scss/mixins";

.document-table-actions{
    background-color: $table-bg !important ;
    border:0.06rem solid $grey-450;
    position: absolute;
    right: 2.5rem;
    padding: 0.5rem;
    z-index:1;
    button {
        background-color: transparent;
        border: 0.06rem solid transparent;
        padding: 0.5rem;
        color: $white;
        cursor: pointer;
        @include flex(row, center, center);
        gap: 0.5rem;
        span{
            font-weight: normal;
        }
    }
    &:after {
        content: " ";
        position: absolute;
        right: 1rem;
        top: -0.5rem;
        border-top: none;
        border-right: 10px solid transparent;
        border-left: 10px solid transparent;
        border-bottom: 10px solid $table-bg;
      }
}