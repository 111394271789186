@import "/src/assets/scss/variables";
@import "/src/assets/scss/mixins";

.chart-skelton-loader {
  background-color: $app-bg;
  position: absolute;
  z-index: 99;
  @include size(100%, 100%);

  &__container {
    padding: 0.75rem;
    border-right: 0.06rem solid $sidebar-border;
    height: 100%;

    &--header {
      margin: 0 -.75rem;
      padding: .75rem;
      @include flex(row, flex-start, center);

      span {
        @include size(13rem, 0.6rem);
        position: relative;
        display: block;
        border-radius: 1rem;
        overflow: hidden;
      }
    }

    &--body {
      background-color: $app-bg;
      padding: 1rem;
      border-radius: 0.5rem;
      margin-top: 1rem;
      height: 75%;
      @include flex(row, space-between, center);

      .chart-circle {
        width: 20%;
        height: 75%;
        border-radius: 50%;

        @include for-max-width-1100px {
          width: 15%;
        }

        @include for-portrait-mode {
          width: 20%;
        }
      }

      .chart-square {
        width: 65%;
        height: 100%;
        border-radius: 4px;
      }

      .chart-circle,
      .chart-square {
        background: $grey-450;
        position: relative;
        overflow: hidden;
      }
    }

    &--body .chart-circle::before,
    &--body .chart-square::before,
    &--header span::before {
      position: absolute;
      content: "";
      @include square(100%);
      background-image: linear-gradient(to right,
          #656871 0%,
          #888b94 20%,
          #656871 40%,
          #656871 100%);
      background-repeat: no-repeat;
      background-size: 450px 400px;
      animation: shimmer 1.5s linear infinite;
    }

    &--body .chart-circle::before {
      background-size: 650px 600px;
    }

    @keyframes shimmer {
      0% {
        background-position: -450px 0;
      }

      100% {
        background-position: 450px 0;
      }
    }
  }
}