@import "/src/assets/scss/variables";
@import "/src/assets/scss/mixins";

.tooltip-content--wrapper {
    padding: 1rem;
    @include flex(column, center, center);
    gap: 1rem;

    .doc-info {
        @include flex();
        gap: 1rem;

        &__status-container {
            @include flex();
            gap: 0.5rem;

            .active,
            .archived {
                height: 8px;
                width: 8px;
                border-radius: 50%;
                background-color: $green-300;
            }

            .archived {
                background-color: $grey-300;
            }
        }

        &__version-container {
            background: #2E2E38;
            color: white;
            padding: 0.2rem 1.2rem;
            border-radius: 20px;
        }
    }
}