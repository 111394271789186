@import "/src/assets/scss/variables";
@import "/src/assets/scss/mixins";

.document-viewer-cell-renderer{
  @include flex(row,flex-start,center);
  width:100%;
  &__data{
    @include flex(row,flex-start,center);
    gap:0.5rem;
    width:100%;
    span.single{
        text-overflow: ellipsis;
        width: 80%;
        overflow: hidden;
    }
    img{
        cursor:pointer;
    }
  }
}