@import "/src/assets/scss/variables";
@import "/src/assets/scss/mixins";
@import "/src/assets/scss/typography";


.more-action-cell-renderer{
    cursor: pointer;
    @include flex(row,flex-end,center);
    width: 100%;
    height: 100%;

}
.add-actions{
    background-color: $black;
    border:1px solid #2C2C2C;
    position: absolute;
    top: 4.5rem;
    right: 1.5rem;
    padding: 0.5rem;
    z-index:2;
    button {
        background-color: transparent;
        border: 0.06rem solid transparent;
        padding: 0.5rem;
        color: $white;
        cursor: pointer;
        @include flex(row, center, center);
        gap: 0.5rem;
        span{
            font-weight: normal;
        }
    }
    &:after {
        content: " ";
        position: absolute;
        right: 1rem;
        top: -0.5rem;
        border-top: none;
        border-right: 10px solid transparent;
        border-left: 10px solid transparent;
        border-bottom: 10px solid $black;
      }
}