@import "/src/assets/scss/variables";
@import "/src/assets/scss/mixins";

.status-cell-renderer{
  @include flex(row,flex-start,center);
  width:100%;
  gap: 0.5rem;
  &__circle{
        @include square(.6rem);
        border-radius: 50%;
        display: inline-block;
  }
  &__name{
       width: 100%;
       text-overflow: ellipsis;
       overflow: hidden;
  }
}