@import "/src/assets/scss/variables";
@import "/src/assets/scss/mixins";

.tree-skelton-loader {
    background-color: $app-bg;
    position: absolute;
    z-index: 9;
    @include size(100%, 100%);

    &__container {
        height: 100%;

        &--body {
            background-color: $app-bg;
            padding: 1rem;
            border-radius: 0.5rem;
            height: 100%;
            @include flex(row, space-between, flex-start);
            gap: 0.2rem;

            .col {
                width: 50%;
                height: 100%;
                @include flex(column, center, flex-start);
                gap: 0.2rem;
            }

            .tree-square {
                width: 100%;
                height: 100%;
                background: $grey-450;
                position: relative;
                overflow: hidden;
            }
        }

        &--body .tree-square::before {
            position: absolute;
            content: "";
            @include square(100%);
            background-image: linear-gradient(to right,
                    #656871 0%,
                    #888b94 20%,
                    #656871 40%,
                    #656871 100%);
            background-repeat: no-repeat;
            background-size: 450px 400px;
            animation: shimmer 1.5s linear infinite;
        }

        @keyframes shimmer {
            0% {
                background-position: -450px 0;
            }

            100% {
                background-position: 450px 0;
            }
        }
    }
}