@import "/src/assets/scss/variables";
@import "/src/assets/scss/mixins";

.data-getter-cell-renderer{
  @include flex(row,flex-start,center);
  &__name{
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }
}