@import "/src/assets/scss/variables";
@import "/src/assets/scss/mixins";
@import "/src/assets/scss/typography";

.select-data {
    position: relative;
      .material-input {
        position: relative;
        width: 100%;
        @include flex(row, space-between, center);
      margin-bottom: 1rem;
      cursor: pointer;
        &:not(:last-child) {
            margin-bottom: 1.5rem;
        }
        input,
        textarea {
            background-color: $grey-600;
        }
        input[type='text'],
        textarea {
            color: $white;
            font-size: 0.85rem;
            padding: 0.9rem 0.9rem 0.9rem 0.7rem;
            position: relative;
            display: block;
            border: none;
            width: 100%;
            border-bottom: 0.06rem solid $white;
            box-sizing: border-box;
            &:focus {
                outline: none;
            }
        }
        input[type='text']:focus + label,
        input[type='text']:valid + label,
        textarea:focus + label,
        textarea:valid + label {
            top: -0.5rem;
            font-size: 0.6rem;
        }
        label {
            color: $white;
            font-size: 0.75rem;
            font-weight: 300;
            position: absolute;
            pointer-events: none;
            top: 6px;
            transition: 0.2s ease all;
            width: 100%;
            left: 0.75rem;
        }
    }
    .options {
      position: absolute;
      background-color: $dropbg;
      padding: 0.5rem;
      width: 100%;
      z-index: 99;
      top: 2.85rem;
      animation: options 0.3s ease;
      max-height:13rem;
      overflow-y: auto;
      @include scrollbars(0.4rem, lighten($grey-450, 25%), $grey-450);
      span {
        @include flex(row, flex-start, center);
        font-size: 0.75rem;
        &:not(:last-child) {
          margin-bottom: 0.5rem;
        }
        img {
          @include square(1.75rem);
          border-radius: 50%;
          margin-right: 0.5rem;
        }
      }
    }
  }