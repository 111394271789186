@import 'assets/scss/variables';
@import "/src/assets/scss/mixins";

//Body
.modal-body {
    padding: calculateRem(20px);
    display: block;
    overflow: auto;
    flex: 1;
    color: $white;
    font-size: 0.875rem;
}