@import "/src/assets/scss/variables";
@import "/src/assets/scss/mixins";
.edit-user {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  @include square(100%);
  z-index: 9;
  &__container {
    @include centre;
    background-color: $modal-bg;
    padding: 1rem;
    animation: fade 0.5s ease-out 1.3s both;
    * {
      transition: all 0.3s ease;
      color: $white;
    }
    .wrapper {
      min-width: 30rem;
      line-height: 2rem;
      header {
        @include flex(row, space-between, center);
        margin-bottom: 1rem;
        h3 {
          font: {
            weight: 300;
          }
        }
        img {
          @include square(0.8rem);
          cursor: pointer;
        }
      }
      form{
      @include flex(column, flex-start, flex-start);
      gap:1rem;
      .user{
        width:100%;
        @include flex(row, flex-start, center);
        gap:.5rem; 
        margin-bottom: 1rem;
        span{
            font-size: 0.75rem;
        }
        img {
            @include square(1.75rem);
            border-radius: 50%;
          }
      }
      .role {
        width:100%;
          margin-bottom: 1rem;
          h5{
              font-weight: 400;
          }
        &__options {
          label {
            display: flex;
            align-items: center;
            position: relative;
            font-size: 0.75rem;
          }

          input {
            display: none;
          }

          label > span {
            @include square(1.3rem);
            display: flex;
            justify-content: center;
            border: 0.06rem solid $white;
            margin-right: 0.5rem;
            transition: all 0.3s;
          }

          input:checked + label > span {
            border: 0.06rem solid transparent;
            animation: bounce 250ms;
          }

          input:checked + label > span::before {
            content: "";
            position: absolute;
            top: 0.8rem;
            left: 4px;
            border-right: 0.1rem solid transparent;
            border-bottom: 0.1rem solid transparent;
            transform: rotate(45deg);
            transform-origin: 0% 100%;
            animation: checked-box 125ms 250ms forwards;
          }

          input:checked + label > span {
            background: $grey-500;
          }

          @keyframes checked-box {
            0% {
              width: 0;
              height: 0;
              border-color: #fff;
              transform: translate(0, 0) rotate(45deg);
            }
            33% {
              width: 4px;
              height: 8px;
              border-color: #fff;
              transform: translate(0, 0) rotate(45deg);
            }
            100% {
              width: 6px;
              height: 10px;
              border-color: #fff;
              transform: translate(0, -8px) rotate(45deg);
            }
          }

          @keyframes bounce {
            0% {
              transform: scale(1);
            }
            33% {
              transform: scale(0.7);
            }
            100% {
              transform: scale(1);
            }
          }
        }
      }
      .active{
        h5{
            font-weight: 400;
        }
        .radio-buttons {
            gap: 1rem;
            @include flex(row, flex-start, center);
            .form-group {
              input[type="radio"] {
                display: none;
              }
              input[type="radio"]:checked + label::before {
                border-color:$white;
              }
  
              input[type="radio"]:checked + label::after {
                transform: translateY(-50%) scale(0.55);
              }
              label {
                cursor: pointer;
                position: relative;
                display: flex;
                font-size: 0.75rem;
                padding-left: 1.5rem;
                &:before {
                  content: "";
                  position: absolute;
                  @include square(0.95rem);
                  background-color: transparent;
                  border: 0.1rem solid $white;
                  border-radius: 50%;
                  top: 50%;
                  left: 0;
                  transform: translateY(-50%);
                  transition: border-color 400ms ease;
                }
                &:after {
                  content: "";
                  position: absolute;
                  @include square(0.95rem);
                  background-color: $white;
                  border: 0.1rem solid $white;
                  border-radius: 50%;
                  top: 50%;
                  left: 0;
                  transform: translateY(-50%) scale(0);
                  transition: transform 400ms ease;
                }
              }
            }
          }
      }
      .select-country{
        width:100%;
      }
      }
      footer {
        @include flex(row, flex-start, center);
        border-top: 0.06rem solid $grey-450;
        gap: 0.75rem;
        margin: 1rem -1rem 0;
        padding: 1rem 1rem 0;
        .btn.btn-primary:not(:disabled){
          color:$black;
        }
      }
    }
  }
  &:before {
    content: "";
    box-sizing: border-box;
    width: 100%;
    background-color: $popup-bg;
    position: fixed;
    left: 0;
    top: 50%;
    will-change: height, top;
    animation: open-animation 0.6s cubic-bezier(0.83, 0.04, 0, 1.16) 0.65s both;
  }
  &:after {
    content: "";
    @include size(0, 0.1rem);
    background-color: $black;
    position: absolute;
    top: 50%;
    left: 0;
    will-change: width, opacity;
    animation: line-animation 0.6s cubic-bezier(0.83, 0.04, 0, 1.16) both;
  }
}
@keyframes options {
  from {
    transform: translateY(-1rem);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
